import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

export const CareateObject = async (setVoteData?:any,voteData?:any)=>{
    const timeframes = process.env.REACT_APP_FIREBASE_PROJECT_ID?.includes("staging")? ["7 Sec", "30 Sec", "60 Sec"]:["10 Sec", "15 Sec", "60 Sec"];
    const avatar = ["founder", "holder", "investor", "trader","angle"];
const coins = [
    "PEPE-FLOKI", "ETH-BNB", "XRP-ADA", "BTC-ETH", "DOGE-SHIB", "SAND-MANA", 
    "DOT-MATIC", "UNI-CAKE", "LTC-XRP", "LINK-TRX", "ADA-SOL", "CRO-BNB", "BTC", 
    "PEPE", "FLOKI", "ETH", "BNB", "ADA", "SOL", "XRP", "DOGE", "DOT", "SHIB", 
    "MATIC", "CRO", "LTC", "LINK", "UNI", "TRX", "XLM", "MANA", "HBAR", "VET", 
    "SAND", "EOS", "CAKE", "TRUMP"
];
const directions = [0, 1];
const multipliers = [1, 3, 5, 10];

const displayNames = [
      // American Names
"Jake", "Emily", "Noah", "Olivia", "Ethan", "Sophia", "Mason", "Ava", "Logan", "Charlotte",

// Indian Names
"Aarav", "Priya", "Rohan", "Ananya", "Kabir", "Sanya", "Arjun", "Neha", "Vihaan", "Meera",

// French Names
"Louis", "Emma", "Hugo", "Camille", "Mathis", "Léa", "Lucas", "Chloé", "Nathan", "Juliette",

// Spanish Names
"Diego", "Lucia", "Carlos", "Sofia", "Javier", "Valeria", "Mateo", "Isla", "Sebastian", "Elena",

// Italian Names
"Luca", "Giulia", "Alessandro", "Sofia", "Riccardo",

// Japanese Names
"Haruto", "Sakura", "Ren", "Yui", "Takumi",

// Chinese Names
"Wei", "Xiao", "Hao", "Mei", "Jun", 

// Russian Names
"Ivan", "Anastasia", "Dmitry", "Natalia", "Nikolai",

// Arabic Names
"Omar", "Layla", "Karim", "Aisha", "Hassan",

// African Names
"Kwame", "Zuri", "Jabari", "Amani", "Taye", 
];

const avatars = ["Founder", "Hodler", "Investor", "Trader", "Angel"];

const generateUsers = (count:number) => {
    return Array.from({ length: count }, () => ({
        displayName: displayNames[Math.floor(Math.random() * displayNames.length)],
        avatar: avatars[Math.floor(Math.random() * avatars.length)]
    }));
};
        // Generate 20 objects
const generateRandomObject = () => ({
    id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
    status: { name: "Member" },
    timeframe: { name: timeframes[Math.floor(Math.random() * timeframes.length)] },
    coin: coins[Math.floor(Math.random() * coins.length)],            
    direction: directions[Math.floor(Math.random() * directions.length)],
    multiplier: multipliers[Math.floor(Math.random() * multipliers.length)],
});

const arrayOfObjects = Array.from({ length: 20 }, generateRandomObject);        
const usersArray = generateUsers(70); 

console.log(arrayOfObjects,"arrayOfObjects");

const randomDisplayName = usersArray[Math.floor(Math.random() * displayNames.length)];
const randomVoteData = arrayOfObjects[Math.floor(Math.random() * arrayOfObjects.length)];   

    const docRef = doc(db, "stats", "voteStatistics");
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
        let currentData = docSnap.data();
          currentData["totalFakeVoteCount"]= currentData.totalFakeVoteCount +1            
          // 3️⃣ Set the updated data back (with merge to prevent overwriting)
          await setDoc(docRef, currentData, { merge: true });
                  
        console.log("Document updated successfully!");
      } else {
        console.error("Document not found!");
      }  
    // const voteData=[{...setAboutUser[0]},{...setAboutUser[1]}];
    const voteDataUpdate=voteData.slice(0, -1);
    console.log(voteData,"voteDatavoteData")
    setVoteData([{                
        ...randomVoteData,
        userDetails:{
        ...randomDisplayName
    }},...voteDataUpdate])

// if(setVoteData)setVoteData()    

console.log("Random Display Name:", randomDisplayName);
console.log("Random Timeframe:", randomVoteData);


}